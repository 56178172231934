<template>
  <a-card class="role-manage">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="角色名称">
              <a-input placeholder="请输入角色名称" v-model="queryParam.keyword" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="创建时间">
              <a-range-picker @change="onChange" v-model="date" />
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryClick">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="reload" @click="clearClick">清空</a-button>
            </span>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item>
              <a-button type="primary" icon="plus" @click="addClick">添加角色</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table
      :columns="columns"
      :dataSource="data"
      @change="pageChange"
      :loading="loading"
      rowKey="id"
      :pagination="pagination"
    >
      <span slot="action" slot-scope="text">
        <a-button type="primary" size="small" @click="editClick(text)">编辑</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          title="角色与用户、权限的关联关系也将被删除！确认删除？"
          okText="是"
          cancelText="否"
          @confirm="delOk(text.id)"
        >
          <a-button type="danger" size="small">删除</a-button>
        </a-popconfirm>
      </span>
    </a-table>
    <edit-role-component
      :showEditModal="visible"
      :editData="editData"
      @cancel="cancelHandle"
      @ok="editHandle"
    ></edit-role-component>
  </a-card>
</template>

<script>
import * as roleApi from '@/api/manage/role'
import EditRoleComponent from './children/EditRoleComponent'

export default {
  name: 'RoleManageView',
  components: {
    EditRoleComponent
  },
  data() {
    return {
      editData: {},
      visible: false,
      roleApi,
      // 表格数据
      data: [],
      date: [],
      loading: false,
      queryParam: {
        page: 0,
        size: 10,
        keyword: '',
        startTime: null,
        endTime: null
      },
      pagination: {
        current: 1,
        total: 0,
        showTotal: function(total, range) {
          return '数据总数：' + total + ''
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50']
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: '角色编码',
          dataIndex: 'name'
        },
        {
          title: '角色名称',
          dataIndex: 'description'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created() {
    this.query()
  },
  methods: {
    editClick(row) {
      this.visible = true
      this.editData = JSON.parse(JSON.stringify(row))
    },
    addClick() {
      this.visible = true
    },
    cancelHandle() {
      this.visible = false
      this.editData = {}
    },
    editHandle() {
      this.cancelHandle()
      this.query()
    },
    handleOk() {
      this.visible = false
    },
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    clearClick() {
      this.queryParam = this.$options.data().queryParam
      this.date = []
    },
    delOk(id) {
      roleApi.deleteRole({ id: id }).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }
        this.$notification.success({
          message: '提示',
          description: res.message
        })
        if (this.data.length === 1 && this.pagination.total > 1) {
          this.queryParam.page -= 1
          this.pagination.current -= 1
        } else if (this.data.length === 1 && this.pagination.total === 1) {
          this.queryParam.page = 0
          this.pagination.current = 1
        }
        this.query()
      })
    },
    pageChange(pageNumber, size) {
      this.pagination.current = pageNumber.current
      this.queryParam.page = pageNumber.current - 1
      this.queryParam.size = pageNumber.pageSize
      this.query()
    },
    queryClick() {
      this.pagination.current = 1
      this.queryParam.page = 0
      this.query()
    },
    query() {
      this.loading = true
      roleApi
        .pageList(this.queryParam)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.data = res.body.data
          this.pagination.total = res.body.total
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less">
.role-manage {
}
.a-table {
  margin-top: 30px;
}
</style>
