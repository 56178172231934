<template>
  <a-modal :title="editModalTitle" :maskClosable="false" v-model="visible" :confirmLoading="confirmLoading" @ok="editHandleOk" @cancel="editHandleCancel" width="1000px" class="edit-role-component">
    <a-form :form="form" @submit="editHandleOk">
      <a-form-item label="角色编码" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-input v-decorator="nameValidate" :disabled="disabledNameInput" />
      </a-form-item>
      <a-form-item label="角色描述" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-input v-decorator="descriptionValidate" />
      </a-form-item>
      <a-form-item label="分配权限" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <tree-select
          searchPlaceholder="请选择"
          :getPopupContainer="triggerNode => triggerNode.parentNode || document.body"
          :treeData="allPermissionList"
          :treeDataSimpleMode="true"
          :showCheckedStrategy="'SHOW_ALL'"
          v-model="defaultPermissions"
          allowClear
          treeCheckable
          multiple
          treeDefaultExpandAll
          @change="treeChange"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { addRole, updateRole } from '@/api/manage/role'
import { getPermissionList } from '@/api/manage/permission'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'EditRoleComponent',
  props: {
    showEditModal: Boolean,
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  components: {
    TreeSelect
  },
  watch: {
    showEditModal: function(newVal, oldVal) {
      this.visible = newVal
      this.createForm()
    },
    editData: function(newVal, oldVal) {
      this.createForm()
      this.newEditData = newVal
      this.nameValidate[1].initialValue = this.newEditData.name
      this.descriptionValidate[1].initialValue = this.newEditData.description
      console.log(this.descriptionValidate)
      // 未传递edit对象，则为新增
      if (Object.keys(this.newEditData).length === 0) {
        this.editModalTitle = '新增角色'
        this.disabledNameInput = false
      } else {
        this.editModalTitle = '编辑角色'
        this.disabledNameInput = true
      }
      if (this.newEditData.managerPermissions) {
        this.defaultPermissions = this.newEditData.managerPermissions.filter(obj => obj.pid !== 0).map(obj => obj.id)
      }
    }
  },
  data() {
    return {
      allPermissionList: [],
      defaultPermissions: [],
      disabledNameInput: false,
      confirmLoading: false,
      visible: false,
      nameValidate: ['name', { rules: [{ required: true, message: '角色编码不能为空！' }] }],
      descriptionValidate: ['description', { rules: [{ required: true, message: '角色描述不能为空！' }] }],
      form: null,
      newEditData: {},
      editModalTitle: '新增角色'
    }
  },
  beforeCreate() {
    if (this.form === null) {
      this.form = this.$form.createForm(this)
    }
  },
  created() {
    this.createForm()
    // 未传递edit对象，则为新增
    if (Object.keys(this.editData).length === 0) {
      this.editModalTitle = '新增角色'
      this.disabledNameInput = false
    } else {
      this.editModalTitle = '编辑角色'
      this.disabledNameInput = true
    }
    this.getPermissionList()
  },
  methods: {
    treeChange(value, label, extra) {
      console.log('value', value)
    },
    getPermissionList() {
      getPermissionList()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allPermissionList = res.body
          this.allPermissionList.map(obj => {
            obj.pId = obj.pid
          })
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    },
    createForm() {
      if (this.form === null) {
        this.form = this.$form.createForm(this)
      }
    },
    editHandleCancel() {
      this.form.resetFields()
      this.$emit('cancel')
    },
    editRoleSubmit(values) {
      if (this.newEditData && this.newEditData.id) {
        values.id = this.newEditData.id
        return updateRole(values)
      } else {
        return addRole(values)
      }
    },
    editHandleOk() {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        this.confirmLoading = true

        // 组装权限参数
        if (this.defaultPermissions.length > 0) {
          values.managerPermissions = []
          // 获取完整权限对象
          const per = this.defaultPermissions.map(val => {
            const permission = this.allPermissionList.filter(obj2 => obj2.id === val)[0]
            return {
              id: permission.id,
              pid: permission.pid
            }
          })

          // 获取父权限对象
          const parentIds = per.map(obj => obj.pid)
          values.managerPermissions = per.concat(
            parentIds
              .filter((e, i) => parentIds.indexOf(e) === i)
              .filter(item => item !== 0)
              .map(item => {
                return { id: item }
              })
          )
        }

        this.editRoleSubmit(values)
          .then(res => {
            if (res.status !== 200) {
              this.$notification.error({
                message: '提示',
                description: res.message
              })
              return
            }
            this.$emit('ok')
            this.$notification.success({
              message: '提示',
              description: res.message
            })
            this.form.resetFields()
          })
          .catch(e => {
            this.$notification.error({
              message: '提示',
              description: e.message
            })
          })
          .finally(() => {
            this.confirmLoading = false
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.edit-role-component {
}
</style>
